import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { history } from '../../utils/history';
import { createUserObj, transformUserObj } from '../../utils/commonFunctions';

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null,
        loading: false,
        authChallengeData: null
    };
}

function createReducers() {
    return {
        logout,
    };

    function logout(state) {
        state.user = null;
        localStorage.removeItem('user');
        history.navigate('/');
    }
}

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_AUTH_URL}`;

    return {
        login: login(),
        forgotPassword: forgotPassword(),
        recoverPassword: recoverPassword(),
        refreshToken: refreshToken(),
        respondToChallenge: respondToChallenge()
    };

    function login() {
        return createAsyncThunk(`${name}/login`, async ({ username, password }) => {
            const form = new FormData();
            form.append('username', username);
            form.append('password', password);
            const body = new URLSearchParams(form);
            try {
                const response = await fetch(baseUrl + '/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: body,
                });
                const data = await response.json();
                if (!data?.idToken && !data?.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                    throw Error(data.message);
                }
                return data;
            } catch (e) {
                throw e;
            }
        });
    }

    function forgotPassword() {
        return createAsyncThunk(`${name}/forgot-password`, async ({ username }) => {
            const form = new FormData();
            form.append('username', username);
            const body = new URLSearchParams(form);
            const response = await fetch(baseUrl + '/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body,
            });
            const data = await response.json();
            if (response.status === 401) {
                throw Error(data.message);
            }
            return data;
        });
    }

    function recoverPassword() {
        return createAsyncThunk(`${name}/recover-password`, async ({ username, password, code }) => {
            const form = new FormData();
            form.append('username', username);
            form.append('password', password);
            form.append('confirmationCode', code);
            const body = new URLSearchParams(form);
            const response = await fetch(baseUrl + '/recover-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body,
            });
            const data = await response.json();
            if (response.status === 401) {
                throw Error(data.message);
            }
            return response;
        });
    }

    function refreshToken() {
        return createAsyncThunk(`${name}/refresh-token`, async ({ userInfo }) => {
            return userInfo;
        });
    }

    function respondToChallenge() {
        return createAsyncThunk(`${name}/repond-to-challenge`, async ({ username, password, sessionToken }) => {
            const form = new FormData();
            form.append('username', username);
            form.append('password', password);
            form.append('sessionToken', sessionToken);
            const body = new URLSearchParams(form);
            const response = await fetch(baseUrl + '/confirm-new-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body,
            });
            const data = await response.json();
            if (data?.$metadata?.httpStatusCode === 200) {
                if (!data?.AuthenticationResult?.AccessToken) {
                    throw Error('No Access Token - ', data.message);
                } else {
                    let userObj = createUserObj({
                        ...data,
                        refreshToken: data?.AuthenticationResult?.AccessToken
                    });
                    localStorage.setItem('user', JSON.stringify(userObj));
                    localStorage.setItem('is-refresh-token-requested', 'false');
                    return userObj;
                }
            } else {
                throw Error(data.message);
            }
        });
    }
}
//sets state
function createExtraReducers() {
    return (builder) => {
        login();
        resetPassword();
        recoverPassword();
        refreshToken();
        respondToChallenge();

        function login() {
            const { pending, fulfilled, rejected } = extraActions.login;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state, action) => {
                    let authResponse = action.payload;
                    // store user details and basic auth data in local storage to keep user logged in between page refreshes
                    // use external auth if outside cps vpn

                    if (authResponse?.idToken) {
                        const user = authResponse;
                        
                        localStorage.setItem('is-refresh-token-requested', 'false');
                        // if (!window.location.hostname.endsWith(".liveline.cloud")) {
                        //     user['apiURL'] = `http://${window.location.hostname}:3003/customer-portal/api/v1`;
                        // }
                        const transformedUserObj = transformUserObj(user);
                        localStorage.setItem('user', JSON.stringify(transformedUserObj));
                        state.user = transformedUserObj;

                        state.loading = false;
                        // get return url from location state or default to home page
                        const { from } = history.location.state || { from: { pathname: '/' } };
                        history.navigate(from);
                    } else if (authResponse?.Session) {
                        state.authChallengeData = authResponse;
                        state.error = "Please create a new password. Passwords must be at least 12 characters long and include upper and lowercase letters, numbers, and special characters."
                    } else {
                        state.error = authResponse.message;
                        state.loading = false;
                    }
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error.message;
                    state.loading = false;
                });
        }

        function refreshToken() {
            const { pending, fulfilled, rejected } = extraActions.refreshToken;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state, action) => {
                    let response = action.payload;
                    // if (!window.location.hostname.endsWith(".liveline.cloud")) {
                    //     response['apiURL'] = `http://${window.location.hostname}:3003/customer-portal/api/v1`;
                    // }

                    const user = transformUserObj(response);
                    
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('is-refresh-token-requested', 'false');
                    state.user = user;
                    
                    state.loading = false;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error.message;
                    state.loading = false;
                });
        }

        function resetPassword() {
            const { pending, fulfilled, rejected } = extraActions.forgotPassword;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state, action) => {
                    // store user details and basic auth data in local storage to keep user logged in between page refreshes
                    state.loading = false;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error.message;
                    state.loading = false;
                });
        }

        function recoverPassword() {
            const { pending, fulfilled, rejected } = extraActions.recoverPassword;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state, action) => {
                    state.loading = false;
                    // store user details and basic auth data in local storage to keep user logged in between page refreshes
                    const { from } = history.location.state || { from: { pathname: '/' } };
                    history.navigate(from);
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error.message;
                    state.loading = false;
                });
        }

        function respondToChallenge() {
            const { pending, fulfilled, rejected } = extraActions.respondToChallenge;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state, action) => {
                    const user = action.payload;
                    // if (!window.location.hostname.endsWith(".liveline.cloud")) {
                    //     user['apiURL'] = `http://${window.location.hostname}:3003/customer-portal/api/v1`;
                    // }
                    const transformedUserObj = transformUserObj(user);

                    localStorage.setItem('user', JSON.stringify(transformedUserObj));
                    state.user = user;
                    state.authChallengeData = null;

                    state.loading = false;
                    // get return url from location state or default to home page
                    const { from } = history.location.state || { from: { pathname: '/' } };
                    history.navigate(from);

                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error.message;
                    state.loading = false;
                });
        }
    };
}
