import Header from './Header';
import { Confirm } from '../../components/shared';
import { authActions } from '../../state/features/authReducer';
import { useDispatch } from 'react-redux';
import useAppLogout from '../../hooks/useAppLogout';

const HeaderContainer = ({ mobile }) => {
    const dispatch = useDispatch();
    const logout = useAppLogout();

    const onLogoutClick = () => {
        Confirm({
            title: 'Logout Confirmation',
            icon: '',
            content: <p>Are you sure you want to Logout?</p>,
            onOk() {
                return logout(dispatch);
            },
            onCancel() {},
            okText: 'Yes',
            cancelText: 'No',
            className: 'isCancel',
        });
    };

    return <Header mobile={mobile} onLogoutClick={onLogoutClick} />;
};

export default HeaderContainer;
