import axios from "axios";
import { Notification } from "../components/shared";
import jwtDecode from "jwt-decode";
import { authActions } from "../state/features/authReducer";
import { createUserObj } from "../utils/commonFunctions";
import useAppLogout from "../hooks/useAppLogout";

// eslint-disable-next-line
const logout = useAppLogout();

export class APIClient {
    logOut = () => {
        logout(this.dispatch);
    };

    checkSessionExpiry = (token) => {
        try {
            let res = false;
            if (token) {
                const decodedPayload = jwtDecode(token);
                if (decodedPayload?.exp) {
                    const expirationTime = decodedPayload.exp;
                    const currentTime = Math.floor(Date.now() / 1000);
                    if (currentTime > expirationTime) {
                        res = true;
                    } else {
                        //console.log("not expired");
                    }
                } else {
                    res = true;
                }
            } else {
                console.log("no token");
                res = true;
            }
            return res;
        } catch (ex) {
            console.error("Exception in checkSessionExpiry - ", ex);
            return true;
        }
    };

    baseUrl = `${process.env.REACT_APP_AUTH_URL}`;

    refreshAccessToken = async (refreshToken) => {
        const form = new FormData();
        form.append("token", refreshToken);
        const body = new URLSearchParams(form);
        try {
            const response = await fetch(this.baseUrl + "/refresh-token", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: body,
            });
            const data = await response.json();
            //console.log(data);
            if (data?.$metadata?.httpStatusCode === 200) {
                if (!data?.AuthenticationResult?.AccessToken) {
                    throw Error("No Access Token - ", data.message);
                } else {
                    let userObj = createUserObj({
                        ...data,
                        refreshToken: this.refreshTokenInitial,
                    });
                    localStorage.setItem("is-refresh-token-requested", "false");
                    return userObj;
                }
            } else {
                throw Error("Failed to fetch New Token!");
            }
        } catch (e) {
            throw e;
        }
    };

    async refreshToken() {
        try {
            const refreshToken = this.refreshTokenInitial;
            if (!refreshToken) {
                this.logOut();
                this.cancelTokenSource.cancel(
                    "Request canceled - Token Expired No refresh token available."
                );
            }
            // Perform the token refresh operation
            const newAccessTokenObj = await this.refreshAccessToken(
                refreshToken
            );
            const newAccessToken = newAccessTokenObj?.accessToken;
            this.dispatch(
                authActions.refreshToken({ userInfo: newAccessTokenObj })
            );
            return newAccessToken;
        } catch (error) {
            this.logOut();
            this.cancelTokenSource.cancel("Request canceled - ", error);
        }
    }

    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    getUserInfo = () => {
        const userObj = localStorage.getItem("user") ?? "";
        const userJson = JSON.parse(userObj);
        const accessToken = userJson?.accessToken;
        const refreshToken = userJson?.refreshToken;
        const expiresIn = userJson?.expiresIn;
        return { accessToken, refreshToken, expiresIn };
    };

    constructor(url, dispatch) {
        this.dispatch = dispatch;

        const userInfo = this.getUserInfo();

        this.refreshTokenInitial = userInfo?.refreshToken;

        // Create a CancelToken source
        this.cancelTokenSource = axios.CancelToken.source();

        this.instance = axios.create({
            baseURL: url,
            headers: {
                Authorization: `Bearer ${userInfo?.accessToken}`,
            },
            cancelToken: this.cancelTokenSource.token,
        });

        this.instance.interceptors.request.use(
            async (config) => {
                if (config?.headers?.Authorization) {
                    try {
                        const userInfo = this.getUserInfo(); // can fail if no user is set, prompting logout
                        const token = userInfo?.accessToken;
                        // Check if the token is expired
                        config.headers.Authorization = `Bearer ${token}`; //make sure it's using the latest
                        const isExpired = this.checkSessionExpiry(token);
                        if (isExpired) {
                            console.log("expired and attempting to refresh");
                            if (!userInfo?.expiresIn) {
                                // Refresh the token (if not already in progress)
                                const newAccessToken = await this.refreshToken();
                                config.headers.Authorization = `Bearer ${newAccessToken}`;
                                return config;
                            } else {
                                this.logOut();
                                this.cancelTokenSource.cancel(
                                    "Request canceled - Token Expired No Refresh Token!"
                                );
                                //throw err
                            }
                        }
                    } catch (error) {
                        console.log("something failed", error);
                        this.logOut();
                        this.cancelTokenSource.cancel(
                            "Request canceled - ",
                            error
                        );
                        //throw err
                    }
                } else {
                    this.logOut();
                    this.cancelTokenSource.cancel(
                        "Request canceled - Authorization not found!"
                    );
                    //throw errr
                }
                return config;
            },
            (error) => {
                this.logOut();
                this.cancelTokenSource.cancel("Error in interceptor - ", error);
            }
        );

        // this.instance.interceptors.response.use(function (response){
        //     return response
        // }, async function (error) {
        //     console.log(error.request);
        //     if (401 === error.request.status) {
        //         // handle error: inform user, go to login, etc
        //         console.log('401');
        //         await this.refreshToken();
        //     } else {
        //         return Promise.reject(error);
        //     }
        // })
    }

    handlePagination = (url, pageNum) => {
        if (pageNum > 1) {
            return `${url}?page_num=${pageNum}`;
        }

        return url;
    };

    catchError = (title, functionName, error) => {
        console.log(`Exception in  ${functionName}:`, error);
        if (!axios.isCancel(error)) {
            Notification.FailedNotification(title, error?.message);
            return {};
        }
    };

    getOrigin() {
        try {
            const url = new URL(this.instance.defaults.baseURL);
            return url.origin;
        } catch (error) {
            console.log(error);
        }
        return this.instance.defaults.baseURL;
    }

    async getRules(cellId, productId) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const response = await this.instance(
                `/rule-configs/rules/cell/${cell_id}/product/${product_id}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Rules Configuration", "getRules", error);
        }
    }

    async getServiceUrl(jobDescription, facilityId, cellId) {
        try {
            const job = String(jobDescription).toLowerCase();
            const facility = String(facilityId).toLowerCase();
            const cell = String(cellId).toLowerCase();
            const queryParams = { job, facility, cell };
            const response = await this.instance(`/service-url`, {
                params: queryParams,
            });
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Service URL", "getServiceUrl", error);
        }
    }

    async getLoadRules(serviceUrl) {
        try {
            const serviceAddress = String(serviceUrl).toLowerCase();
            const response = await this.instance(
                `/rules-controller/loadrules/instance/${serviceAddress}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Rules Controller", "getLoadRules", error);
        }
    }

    async getImage(cellId, productId) {
        try {
            const response = await this.instance(
                `/product-images/cell/${cellId}/product/image/${productId}`
            );
            return response;
        } catch (error) {
            //his.catchError('Rules Configuration', 'getImage', error);
        }
    }

    async getAllFacilities() {
        try {
            const response = await this.instance.get(`/facilities/facilities`);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Facilities", "getFacilities", error);
        }
    }

    async getFacilityCells(facility_id) {
        try {
            const facilityId = Number(facility_id);
            const response = await this.instance.get(
                `/cells/cells/facility/${facilityId}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Cells", "getCells", error);
        }
    }

    async getProductCellAssignedPart(part_id, cell_id, page_num, page_size) {
        try {
            const productId = Number(part_id);
            const cellId = Number(cell_id);
            const queryParams = { page_num, page_size };
            const response = await this.instance.get(
                `/products-assigned/assigned-parts/cell/${cellId}/products/${productId}`,
                { params: queryParams }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProductCellAssignedPart", error);
        }
    }

    async getRecordPartAttribute(
        facility_line_id,
        part_id,
        attribute_id,
        facility_line_assigned_parts_id
    ) {
        try {
            const cell_id = Number(facility_line_id);
            const queryParams = {
                part_id,
                attribute_id,
                facility_line_assigned_parts_id,
            };
            const response = await this.instance.get(
                `/part-attributes/part-attributes/cellId/${cell_id}`,
                { params: queryParams }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getRecordPartAttribute", error);
        }
    }

    async getCellParts(cell_id, only_active, page_num) {
        try {
            const cellId = Number(cell_id);
            const queryParams = { only_active, page_num };
            const response = await this.instance.get(
                `/products-assigned/assigned-parts/cell/${cellId}/products`,
                { params: queryParams }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Parts", "getCellAssignedParts", error);
        }
    }

    async getFacilityProducts(facility_id) {
        try {
            const facilityId = Number(facility_id);
            const response = await this.instance.get(
                `/products/facilityId/${facilityId}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Parts", "getFacilityProducts", error);
        }
    }

    async getEquipment(cell_id) {
        try {
            const cellId = Number(cell_id);
            const response = await this.instance.get(
                `/equipment/cell/${cellId}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Equipment", "getEquipment", error);
        }
    }

    async getEquipmentTypes(cell_id) {
        try {
            const response = await this.instance.get(
                `/equipment/meta/equipment-types`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Equipment", "getEquipmentTypes", error);
        }
    }

    async updateEquipment(id, editEquipmentRequestBody) {
        try {
            const url = `/equipment/equipmentid?id=${id}`;
            const response = await this.instance.put(
                url,
                editEquipmentRequestBody
            );
            return response?.status;
        } catch (error) {
            this.catchError("Equipment", "updateEquipment", error);
        }
    }

    async postRulesProfileImage(cellId, productId, imageRequestBody) {
        try {
            const formData = new FormData();
            formData.append("file", imageRequestBody);

            const response = await this.instance.post(
                `product-images/cell/${cellId}/product/image/${productId}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        ...this.instance.headers,
                    },
                }
            );
            Notification.SuccessNotification(
                "Complete",
                "Image uploaded successfully"
            );
            return response?.status;
        } catch (error) {
            this.catchError("Image", "postRulesProfileImage", error);
        }
    }

    async deleteImage(cellId, Id) {
        try {
            const cell_id = Number(cellId);
            const id = Number(Id);
            const url = `product-images/cell/${cell_id}/product/image/id/${id}`;
            const response = await this.instance.delete(url);
            Notification.SuccessNotification(
                "Rules Configuration",
                "Deleted Successfully!"
            );
            return response?.status;
        } catch (error) {
            this.catchError("Rules Configuration", "deleteImage", error);
        }
    }

    async createRule(cellId, productId, newRuleRequestBody) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const url = `/rule-configs/rules/cell/${cell_id}/product/${product_id}`;
            const response = await this.instance.post(url, newRuleRequestBody);
            return response?.status;
        } catch (error) {
            this.catchError("Rules Configuration", "createRule", error);
        }
    }

    async updateRule(cellId, ruleId, editRuleRequestBody) {
        try {
            const cell_id = Number(cellId);
            const rule_id = Number(ruleId);
            const url = `/rule-configs/rules/cell/${cell_id}/rule/${rule_id}`;
            const response = await this.instance.put(url, editRuleRequestBody);
            return response?.status;
        } catch (error) {
            this.catchError("Rules Configuration", "updateRule", error);
        }
    }

    async deleteRule(cellId, ruleId) {
        try {
            const cell_id = Number(cellId);
            const rule_id = Number(ruleId);
            const url = `/rule-configs/rules/cell/${cell_id}/rule/${rule_id}`;
            const response = await this.instance.delete(url);
            Notification.SuccessNotification(
                "Rules Configuration",
                "Deleted Successfully!"
            );
            return response?.status;
        } catch (error) {
            this.catchError("Rules Configuration", "deleteRule", error);
        }
    }

    async getStateActionMatrices(cellId, productId) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const url = `/state_action_matrix/cell/${cell_id}/product/${product_id}`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError(
                "State Action Matrix Configuration",
                "getStateActionMatrices",
                error
            );
        }
    }

    async getStateActionMatrixHistory(cellId, matrixId) {
        try {
            const cell_id = Number(cellId);
            const matrix_id = Number(matrixId);
            const url = `/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}/history`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError(
                "State Action Matrix Configuration History",
                "getStateActionMatrixHistory",
                error
            );
        }
    }

    async loadMatrix(matrixinstance) {
        try {
            const instance = String(matrixinstance).toLowerCase();
            const response = await this.instance(
                `/matrix-controller/loadmatrix/instance/${instance}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Matrix Controller", "loadMatrix", error);
        }
    }

    // async getStateActionMatrix(cellId, matrixId) {
    //     try {
    //         const cell_id = Number(cellId);
    //         const matrix_id = Number(matrixId);
    //         const response = await axios.get(`http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}`);
    //         return response?.data ?? {};
    //     } catch (error) {
    //         this.catchError('State Action Matrix Configuration', 'getStateActionMatrices', error);
    //     }
    // }

    // async getStateActionMatrixHistory(cellId, matrixId) {
    //     try {
    //         const cell_id = Number(cellId);
    //         const matrix_id = Number(matrixId);
    //         const response = await axios.get(`http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}/history`);
    //         return response?.data ?? {};
    //     } catch (error) {
    //         this.catchError('State Action Matrix Configuration', 'getStateActionMatrixHistory', error);
    //     }
    // }

    // async getStateActionMatrixVersion(cellId, matrixId, version) {
    //     try {
    //         const cell_id = Number(cellId);
    //         const matrix_id = Number(matrixId);
    //         const response = await axios.get(`http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}/history/${version}`);
    //         return response?.data ?? {};
    //     } catch (error) {
    //         this.catchError('State Action Matrix Configuration', 'getStateActionMatrices', error);
    //     }
    // }

    async createStateActionMatrix(cellId, productId, requestBody) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const url = `/state_action_matrix/cell/${cell_id}/product/${product_id}`;
            const response = await this.instance.post(url, requestBody);
            return response?.status;
        } catch (error) {
            this.catchError(
                "State Action Matrix Configuration",
                "createStateActionMatrix",
                error
            );
        }
    }

    async createStateActionMatrixHistory(cellId, matrixId, requestBody) {
        try {
            const cell_id = Number(cellId);
            const matrix_id = Number(matrixId);
            const url = `/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}/history`;
            const response = await this.instance.post(url, requestBody);
            return response?.status;
        } catch (error) {
            this.catchError(
                "State Action Matrix Configuration",
                "createStateActionMatrix",
                error
            );
        }
    }

    // async updateStateActionMatrix(cellId, matrixId, requestBody) {
    //     try {
    //         const cell_id = Number(cellId);
    //         const matrix_id = Number(matrixId);
    //         const response = await axios.put(
    //             `http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}`,
    //             requestBody
    //         );
    //         return response?.status;
    //     } catch (error) {
    //         this.catchError(
    //             "State Action Matrix Configuration",
    //             "updateStateActionMatrix",
    //             error
    //         );
    //     }
    // }

    async updateStateActionMatrix(cellId, matrixId, requestBody) {
        try {
            const cell_id = Number(cellId);
            const matrix_id = Number(matrixId);
            const url = `/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}`;
            const response = await this.instance.put(url, requestBody);
            return response?.status;
        } catch (error) {
            this.catchError("State Action Matrix Configuration", "updateStateActionMatrix", error);
        }
    }

    async getRuns(requestData) {
        try {
            const {
                facility_id,
                cell_id,
                product_id,
                run_id,
                start,
                end,
                page_size,
                page_num,
                q,
                sort,
                sort_order,
            } = requestData;
            const queryParams = {
                cell_id,
                product_id,
                run_id,
                start,
                end,
                page_size,
                page_num,
                q,
                sort,
                sort_order,
            };

            const runList = await this.instance.get(`/runs/${facility_id}`, {
                params: queryParams,
            });

            if (runList?.status === 200) {
                return runList?.data ?? {};
            } else {
                Notification.FailedNotification(
                    "Historical Runs",
                    "Failed to fetch Run data"
                );
                return {};
            }
        } catch (ex) {
            this.catchError("Rules Configuration", "getRuns", ex);
        }
    }

    async getCurrentRun(facilityId, cellId) {
        try {
            const response = await this.instance.get(
                `/runs/current/cell/${cellId}`
            );

            // Alert user that the Cell is down if there is no current run returned-
            const responseLength = response.data.data.data.length;
            if (responseLength === 0) {
                Notification.InformationNotification(
                    "Cell Down",
                    "Current run not found."
                );
            }

            return response?.data;
        } catch (error) {
            console.error("Exception in getCurrentRun: ", error);
            Notification.FailedNotification("Runs", error?.message);
            return [];
        }
    }

    async getControllersByCell(cellId) {
        try {
            const response = await this.instance.get(
                `/rule-configs/controllers/cell/${cellId}`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError(
                "Rules Configuration",
                "getControllersByCell",
                error
            );
        }
    }

    async getCellStatusTimer(isLatest = true, cellId = "", facilityId = "") {
        try {
            const queryParams = {
                cellId,
                facilityId,
            };

            // Use reduce to construct params object with only non-empty values
            const params = Object.keys(queryParams).reduce((acc, key) => {
                if (queryParams[key]) {
                    acc[key] = queryParams[key];
                }
                return acc;
            }, {});

            // Convert params object to query string
            const queryString = new URLSearchParams(params).toString();

            // Construct the full URL with query string
            const url = `/cell-timer/get_statuses?isLatest=${isLatest}&${queryString}`;
            // Append the isLast query parameter to the endpoint URL
            const response = await this.instance.get(url);
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Cell Status Timer", "getCellStatusTimer", error);
        }
    }

    async createCellStatusTimer(newStatusTimerBody) {
        try {
            const url = `/cell-timer/updateStatusTimer`;
            const response = await this.instance.post(url, newStatusTimerBody);
            return response?.status;
        } catch (error) {
            this.catchError(
                "Cell Status Timer",
                "createCellStatusTimer",
                error
            );
        }
    }

    async postScrapInfo(scrapDataBody, t) {
        try {
            const url = `/cell-timer/update-scrap-info`;
            const response = await this.instance.post(url, scrapDataBody);
            if (response?.status === 201) {
                Notification.SuccessNotification(t("general.scrap-registered"));
            }
            return response?.status;
        } catch (error) {
            this.catchError("Scrap", "postScrap", error);
        }
    }

    async updateDisabledReason(disabledReasonBody) {
        try {
            const url = `/cell-timer/update-disabled-reason`;
            const response = await this.instance.post(url, disabledReasonBody);

            return response?.status;
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;
                if (status === 404 && data.error === "No data found") {
                    Notification.InformationNotification(
                        "Disabled Reason",
                        "No Data Found"
                    );
                }
            } else {
                this.catchError(
                    "Disabled Reason",
                    "updateDisabledReason",
                    error
                );
            }
        }
    }

    async updateDowntimeReason(downtimeReasonBody) {
        try {
            const url = `/cell-timer/update-downtime-reason`;
            const response = await this.instance.post(url, downtimeReasonBody);

            return response?.status;
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;
                if (status === 404 && data.error === "No data found") {
                    Notification.InformationNotification(
                        "Downtime Reason",
                        "No Data Found"
                    );
                }
            } else {
                this.catchError(
                    "Downtime Reason",
                    "updateDowntimeReason",
                    error
                );
            }
        }
    }

    async getScrapInfoPareto(body) {
        try {
            const url = "/cell-timer/scrap-chart-info";
            const response = await this.instance.post(url, body);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Scrap", "getScrapInfoPareto", error);
        }
    }

    async getDisabledChartInfoPareto(body) {
        try {
            const url = "/cell-timer/disabled-chart-info";
            const response = await this.instance.post(url, body);
            return response?.data ?? {};
        } catch (error) {
            this.catchError(
                "Disabled Reasons",
                "getDisabledChartInfoPareto",
                error
            );
        }
    }

    async getDowntimeChartInfoPareto(body) {
        try {
            const url = "/cell-timer/downtime-chart-info";
            const response = await this.instance.post(url, body);
            return response?.data ?? {};
        } catch (error) {
            this.catchError(
                "Downtime Reasons",
                "getDowntimeChartInfoPareto",
                error
            );
        }
    }

    // This API return array of objects for Liveline dashboard to populate values for OEE and Metrics KPIs
    async getOEEMetrics(isLatest = true, cellId = "", facilityId = "") {
        try {
            const queryParams = {
                cellId,
                facilityId,
            };

            // Use reduce to construct params object with only non-empty values
            const params = Object.keys(queryParams).reduce((acc, key) => {
                if (queryParams[key]) {
                    acc[key] = queryParams[key];
                }
                return acc;
            }, {});

            // Convert params object to query string
            const queryString = new URLSearchParams(params).toString();

            // Construct the full URL with query string
            const url = `/cell-oee/get_oeeMetrics?isLatest=${isLatest}&${queryString}`;
            // Append the isLatest query parameter to the endpoint URL
            const response = await this.instance.get(url);
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Cell OEE Metrics", "getOEEMetrics", error);
        }
    }

    async getAlertsLog(cellId) {
        try {
            const url = `/alerts/cell/${cellId}/log`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Alerts Log", "getAlertsLog", error);
        }
    }

    async updateOEEMetrics(payload) {
        try {
            const url = `/cell-oee/updateOEEMetrics`;
            const response = await this.instance.post(url, payload);
            return response?.status;
        } catch (error) {
            this.catchError("Cell OEE Metrics", "updateOEEMetrics", error);
        }
    }

    async getMeasurementSensors(cellId, equipmentId) {
        try {
            const response = await this.instance.get(
                `/rule-configs/${cellId}/sensors/${equipmentId}`
            );

            return response?.data ?? [];
        } catch (error) {
            this.catchError(
                "Rules Configuration",
                "getMeasurementSensors",
                error
            );
        }
    }

    async getMeasurementEquipment(cellId) {
        try {
            const response = await this.instance.get(
                `/rule-configs/outputs/cell/${cellId}/equipment`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Equipment", "getMeasurementEquipment", error);
        }
    }

    async getCaliperDetails(cellId, equipmentDataTagId) {
        try {
            const cell_id = Number(cellId);
            const equipmentDataTag_id = Number(equipmentDataTagId);
            const response = await this.instance(
                `/rule-configs/cell/${cell_id}/equipmentdatatagid/${equipmentDataTag_id}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Rules Configuration", "getCaliperDetails", error);
        }
    }

    async getEquipmentTemplate(reqObj) {
        try {
            const cellId = reqObj?.cell?.id;

            const result = await this.instance.get(`/ui-templates/${cellId}`);

            if (result?.status === 200) {
                const equipmentData = result?.data?.data ?? {};
                return equipmentData;
            } else {
                Notification.FailedNotification(
                    "Signal Data",
                    "Failed to fetch Signal Data Template"
                );
                return {};
            }
        } catch (ex) {
            this.catchError("Signal Data", "getEquipmentTemplate", ex);
        }
    }

    async getControllableEquipByCell(cellId) {
        try {
            const cell_id = Number(cellId);
            const response = await this.instance.get(
                `/equipment/controllers/cell/${cell_id}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError(
                "Controllable Equipment",
                "getControllableEquipByCell",
                error
            );
        }
    }

    async getProductListByCell(cellId, pageNum) {
        try {
            const cell_id = Number(cellId);
            const newUrl = this.handlePagination(
                `/products/cell/${cell_id}`,
                pageNum
            );
            const response = await this.instance.get(newUrl);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProductListByCell", error);
        }
    }

    async getProductDetailsByCellAndProduct(cellId, productId) {
        try {
            const cell_id = Number(cellId);
            const newUrl = this.handlePagination(
                `/products/cell/${cell_id}/product_id/${productId}`
            );
            const response = await this.instance.get(newUrl);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProductListByCell", error);
        }
    }

    async getProductsByFacility(facilityId, pageNum) {
        try {
            const facility_id = Number(facilityId);
            const newUrl = this.handlePagination(
                `/products/facility/${facility_id}`,
                pageNum
            );
            const response = await this.instance.get(newUrl);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProductsByFacility", error);
        }
    }

    async getProducts(productNumber) {
        try {
            const response = await this.instance.get(
                `/parts/parts/product-number/${productNumber}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProducts", error);
        }
    }

    async putProducts(partId, productRequestBody) {
        try {
            const id = Number(partId);
            const queryParams = { id };
            const url = `/parts/parts/part_id`;
            const response = await this.instance.put(url, productRequestBody, {
                params: queryParams,
            });
            return response?.status;
        } catch (error) {
            this.catchError("Products", "putProducts", error);
        }
    }

    async postProducts(productRequestBody) {
        try {
            const url = `/parts/parts`;
            const response = await this.instance.post(url, productRequestBody);
            return response?.status;
        } catch (error) {
            this.catchError("Products", "postProducts", error);
        }
    }

    async postProductFacility(productRequestBody) {
        try {
            const url = `/part-facility/part-facilities`;
            const response = await this.instance.post(url, productRequestBody);
            return response?.status;
        } catch (error) {
            this.catchError("Products", "postProductFacility", error);
        }
    }

    async getProductsByERPNumber(erpNumber) {
        try {
            const url = `/parts/parts/product-number/${erpNumber}`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProductsByERPNumber", error);
        }
    }

    async getCellStatusbyFacilityId(facilityId) {
        try {
            const url = `/cell-status/cell_status/facility/${facilityId}`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Dashboard", "getCellStatusbyFacilityId", error);
        }
    }

    async getMappingByProductsFacility(part_id, facility_id) {
        try {
            const partId = Number(part_id);
            const facilityId = Number(facility_id);
            const url = `/part-facility/part-facilities/partId/${partId}/facilityId/${facilityId}`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getMappingByProductsFacility", error);
        }
    }

    async putProductionStatusProductsFacility(
        partFacilityId,
        productRequestBody
    ) {
        try {
            const id = Number(partFacilityId);
            const queryParams = { id };
            const url = `/part-facility/part-facilities/part_facility_id`;
            const response = await this.instance.put(url, productRequestBody, {
                params: queryParams,
            });
            return response?.status;
        } catch (error) {
            this.catchError(
                "Products",
                "putProductionStatusProductsFacility",
                error
            );
        }
    }

    async putFacilityLineAssignedParts(cellId, facilityLineRequestBody) {
        try {
            const cell_id = Number(cellId);
            const url = `/products-assigned/assigned-parts/cell/${cell_id}/product`;
            const response = await this.instance.put(
                url,
                facilityLineRequestBody
            );
            return response?.status;
        } catch (error) {
            this.catchError("Products", "putFacilityLineAssignedParts", error);
        }
    }

    async putPartAttributeValuesFacilityLine(
        partAttributeValuesFacilityLineRequestBody
    ) {
        try {
            const url = `/part-attributes/part-attributes`;
            const response = await this.instance.put(
                url,
                partAttributeValuesFacilityLineRequestBody
            );
            return response?.status;
        } catch (error) {
            this.catchError(
                "Products",
                "putPartAttributeValuesFacilityLine",
                error
            );
        }
    }

    async postPartAttributeValuesFacilityLine(
        partAttributeValuesFacilityLineRequestBody
    ) {
        try {
            const url = `/part-attributes/part-attributes`;
            const response = await this.instance.post(
                url,
                partAttributeValuesFacilityLineRequestBody
            );
            return response?.status;
        } catch (error) {
            this.catchError(
                "Products",
                "postPartAttributeValuesFacilityLine",
                error
            );
        }
    }

    async postFacilityLineAssignedParts(
        cell_id,
        part_id,
        masterFacilityLineAssignedPartsRequestBody
    ) {
        try {
            const cellId = Number(cell_id);
            const productId = Number(part_id);
            const url = `/products-assigned/assigned-parts/cell/${cellId}/product/${productId}`;
            const response = await this.instance.post(
                url,
                masterFacilityLineAssignedPartsRequestBody
            );
            return response?.status;
        } catch (error) {
            this.catchError("Products", "postFacilityLineAssignedParts", error);
        }
    }

    async getProductionStatus() {
        try {
            const response = await this.instance.get(
                `/products-status/enum/production-status`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Production Status", "getProductionStatus", error);
        }
    }

    async getOPCDevices() {
        try {
            const response = await this.instance.get(`/equipment/opc/devices`);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("OPC Devices", "getOPCDevices", error);
        }
    }

    async getOPCConnections(cellId) {
        try {
            const cell_id = Number(cellId);
            const response = await this.instance.get(
                `/customer-instance/opc-connections`,
                { params: { cell_id: cell_id } }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("OPC Connections", "getOPCConnections", error);
        }
    }

    async getDestinationTypes() {
        try {
            const response = await this.instance.get(
                `/alerts/destination-types`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Alerts", "getDestinationTypes", error);
        }
    }

    async getAlertTypes() {
        try {
            const response = await this.instance.get(
                `/alerts/alert-types`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Alerts", "getAlertTypes", error);
        }
    }

    async getNumericAlertTargets(cellId, dataTagTypeId) {
        try {
            const response = await this.instance.get(
                `/equipment/equipment_tag/cell/${cellId}/numeric?dataTagTypeId=${dataTagTypeId}`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Alerts", "getAlertTargets", error);
        }
    }

    async getRelatedTargets(cellId, equipmentComponentElementId) {
        try {
            const response = await this.instance.get(
                `/equipment/equipment_tag/cell/${cellId}/equipment_component_element/${equipmentComponentElementId}`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Alerts", "getRelatedTargets", error);
        }
    }

    async createAlertConfiguration(cellId, alertConfigurationRequestBody) {
        try {
            const response = await this.instance.post(
                `/alerts/cell/${cellId}/config`,
                alertConfigurationRequestBody
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "createAlertConfiguration", error);
        }
    }

    async deleteAlertConfiguration(cellId, alertConfigId) {
        try {
            const response = await this.instance.delete(
                `/alerts/cell/${cellId}/config/${alertConfigId}`
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "deleteAlertConfiguration", error);
        }
    }

    async updateAlertConfiguration(cellId, alertConfigId, alertConfigurationRequestBody) {
        try {
            const response = await this.instance.put(
                `/alerts/cell/${cellId}/config/${alertConfigId}`,
                alertConfigurationRequestBody
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "updateAlertConfiguration", error);
        }
    }

    async subscribeUserToAlert(cellId, alertConfigId, alertSubscriptionRequestBody) {
        try {
            const response = await this.instance.post(
                `/alerts/cell/${cellId}/config/${alertConfigId}/user/subscription`,
                alertSubscriptionRequestBody
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "subscribeUserToAlerts", error);
        }
    }

    async unsubscribeUserFromAlert(cellId, alertConfigId, userId) {
        try {
            const response = await this.instance.delete(
                `/alerts/cell/${cellId}/config/${alertConfigId}/user/subscription/${userId}`,
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "subscribeUserToAlerts", error);
        }
    }

    async getAlertConfigurations(cellId) {
        try {
            const response = await this.instance.get(
                `/alerts/cell/${cellId}/config`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Alerts", "getAlertConfigurations", error);
        }
    }

    async getInputSignalsData(cellId, productId, reqBody) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const { page_size, page_num, sort, sort_order } = reqBody;
            const queryParams = { page_size, page_num, sort, sort_order };

            const response = await this.instance.get(
                `/data_science/physics_controllable_input_signals/cell/${cell_id}/product/${product_id}`,
                { params: queryParams }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("ML Configuration", "getInputSignalsData", error);
        }
    }

    async getDashboardUrlList(cell_id, service_type_id) {
        try {
            const queryParams = {
                cell_id: Number(cell_id),
                service_type_id: service_type_id,
                current_run: true,
            };
            let response = await this.instance.get(`/dashboards`, {
                params: queryParams,
            });

            // Alert user if no dashboards are found / returned-
            const responseLength = response.data.length;
            if (responseLength === 0) {
                Notification.InformationNotification(
                    "Dashboards",
                    "No dashboards found."
                );
            }

            return response?.data ?? [];
        } catch (error) {
            this.catchError("Dashboard", "getDashboardUrlList", error);
        }
    }

    async getOutputSignalsData(cellId, productId, reqBody) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const { page_size, page_num, sort, sort_order } = reqBody;
            const queryParams = { page_size, page_num, sort, sort_order };

            const response = await this.instance.get(
                `/data_science/physics_output_signals/cell/${cell_id}/product/${product_id}`,
                { params: queryParams }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("ML Configuration", "getOutputSignalsData", error);
        }
    }

    async getDashboardsByRunId(reqObj) {
        try {
            const { run_id, cell_id } = reqObj;
            const response = await this.instance.get(
                `/dashboards/cellid/${cell_id}/runid/${run_id}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Dashboard", "getDashboardsByRunId", error);
        }
    }

    async getRunReportPDFUrl(cell_id, run_id) {
        try {
            const response = await this.instance.get(
                `/reporting/cell/${cell_id}/run/${run_id}/summary/pdf`
            );
            return response ?? {};
        } catch (error) {
            const errorMessage = { message: error.response.data.detail };
            this.catchError(
                "Historical Runs",
                "getRunReportPDFUrl",
                errorMessage
            );
        }
    }

    async getShapelyChartPNGUrl(cell_id, run_id) {
        try {
            const response = await this.instance.get(
                `/reporting/cell/${cell_id}/run/${run_id}/shapely/png`
            );
            return response ?? {};
        } catch (error) {
            const errorMessage = { message: error.response.data.detail };
            this.catchError(
                "Historical Runs",
                "getShapelyChartPNGUrl",
                errorMessage
            );
        }
    }

    async getDataScienceDPK(cellId, runId) {
        try {
            const cell_id = Number(cellId);
            const run_id = Number(runId);

            const response = await this.instance.get(
                `/data_science/dpk/cell/${cell_id}/run/${run_id}`
            );
            return response?.data ?? {};
        } catch (error) {
            const errorMessage = { message: error.response.data.detail };
            this.catchError("Historical Runs", "getDataScienceDPK", errorMessage);
        }
    }

    // This API return array of objects for Liveline Utilization to populate values for graph
    async getLLUtilizationData(
        startDate = "",
        endDate = "",
        cellId = "",
    ) {
        try {
            const queryParams = {
                startDate,
                endDate,
            };
            // Construct the full URL with query string
            const url = `/reporting/cell/${cellId}/shifts/summary`;

            // Append the isLatest query parameter to the endpoint URL
            const response = await this.instance.get(url, {
                params: queryParams
            });
            return response?.data ?? [];
        } catch (error) {
            this.catchError(
                "Liveline Utilization",
                "getLLUtilizationData",
                error
            );
        }
    }

    async getCellShifts(cellId) {
        try {
            const response = await this.instance.get(`/oee/cell/${cellId}/shifts`);
            return response?.data ?? [];
        } catch (error) {
            this.catchError(
                "Cell Shifts",
                "getCellShifts",
                error
            );
        }
    }

    // This API return array of objects for OEE to populate values for graph
    async getOEEData(
        startDate = "",
        endDate = "",
        cellId = "",
        facilityId = "",
        shift = "",
        profileId = "",
        timeUnit = ""
    ) {
        try {
            const queryParams = {
                startDate,
                endDate,
                cellId,
                facilityId,
                shift,
                profileId,
                timeUnit,
            };

            // Use reduce to construct params object with only non-empty values
            const params = Object.keys(queryParams).reduce((acc, key) => {
                if (queryParams[key]) {
                    acc[key] = queryParams[key];
                }
                return acc;
            }, {});

            // Convert params object to query string
            const queryString = new URLSearchParams(params).toString();

            // Construct the full URL with query string
            const url = `/cell-oee/get-oee-data?${queryString}`;

            // Append the isLatest query parameter to the endpoint URL
            const response = await this.instance.get(url);
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Cell OEE Data", "getOEEData", error);
        }
    }

    // This API return array of objects for LiveLine Cpk to populate values for graph
    async getLLCpk(
        startDate = "",
        endDate = "",
        cellId = "",
        facilityId = "",
        shift = "",
        profileId = "",
        timeUnit = ""
    ) {
        try {
            const queryParams = {
                startDate,
                endDate,
                cellId,
                facilityId,
                shift,
                profileId,
                timeUnit,
            };

            // Use reduce to construct params object with only non-empty values
            const params = Object.keys(queryParams).reduce((acc, key) => {
                if (queryParams[key]) {
                    acc[key] = queryParams[key];
                }
                return acc;
            }, {});

            // Convert params object to query string
            const queryString = new URLSearchParams(params).toString();

            // Construct the full URL with query string
            const url = `/cell-oee/get-ll-cpk-data?${queryString}`;

            const response = await this.instance.get(url);
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Cell OEE Liveline Cpk", "getLLCpk", error);
        }
    }

    //This API is used to make push subscribe user
    async pushSubscribeUser(payload) {
        try {
            const url = `/push/subscribe`;
            const response = await this.instance.post(url, payload);
            return response?.status;
        } catch (error) {
            this.catchError("Push Subscribe User", "pushSubscribeUser", error);
        }
    }

    //This API is used to make push notification
    async pushNotification(payload) {
        try {
            const url = `/push/send-notification`;
            const response = await this.instance.post(url, payload);
            return response?.status;
        } catch (error) {
            this.catchError("Push Notification", "pushNotification", error);
        }
    }

    // This API return a list of all scrap
    async getScrapReasons(cellId) {
        try {
            const url = `/oee/recorded_scrap/cell/${cellId}`;

            const response = await this.instance.get(url);
            return response?.data ?? [];
        } catch (error) {
            this.catchError(
                "Cell Timer get scrap reasons",
                "getScrapReasons",
                error
            );
        }
    }
    async createUser(createUserBody) {
        try {
            const url = "/signup";
            const response = await this.instance.post(url, createUserBody);
            return response;
        } catch (error) {
            this.catchError("Admin User", "createUser", error);
        }
    }

    async getRoles() {
        try {
            const response = await this.instance.get(`/roles`);
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Roles", "getRoles", error);
        }
    }

    async getUsers() {
        try {
            const response = await this.instance.get(`/users`);
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Users", "getUsers", error);
        }
    }

    async getFacilityUsers() {
        try {
            const response = await this.instance.get(`/facility-users`);
            return response?.data ?? [];
        } catch (error) {
            this.catchError("FacilityUsers", "getFacilityUsers", error);
        }
    }

    async updateUser(id, body) {
        try {
            const response = await this.instance.put(`/users/${id}`, body);
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Users", "updateUsers", error);
        }
    }

    async updateFacilityUser(id, body) {
        try {
            const response = await this.instance.put(
                `/facility-users/${id}`,
                body
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("FacilityUsers", "updateFacilityUsers", error);
        }
    }

    async getFacilityUserByUserId(id) {
        try {
            const response = await this.instance.get(
                `/facility-users/user/${id}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("FacilityUser", "getFacilityUser", error);
        }
    }

    // This API writes an event when a rule is updated-
    async createRuleEvent(cell_id, requestBody) {
        try {
            const cellId = Number(cell_id);
            const url = `/events/facilityline/cell/${cellId}`;
            await this.instance.post(url, requestBody);
            //return response?.status; // this action is not for the users benefit-
        } catch (error) {
            this.catchError("Rules Configuration", "createRuleEvent", error);
        }
    }

    // This API fetches the last run in all the cells for the selected facility -
    async getLastRun(facility) {
        try {
            const response = await this.instance.get(
                `/model-metrics/${facility}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Runs", "getLastRun", error);
        }
    }

    // This API fetches the runs for the selected facility and cell -
    async getRunsForCells(facility, cell) {
        try {
            const response = await this.instance.get(
                `/model-metrics/${facility}/cell/${cell}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Runs", "getRunsForCells", error);
        }
    }

    // This API fetches the detailed run for the selected facility, cell and run -
    async getDetailedRun(facility, cell, run) {
        try {
            const response = await this.instance.get(
                `/model-metrics/${facility}/cell/${cell}/run/${run}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Runs", "getDetailedRun", error);
        }
    }
}
