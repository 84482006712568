import React from 'react';
import { InputNumber, Form } from 'antd';

const FormInputNumberComponent = ({ name, rules, autoComplete, onKeyPress, className, placeholder, disabled, addonAfter, min, max, step, precision }) => {
    return (
        <Form.Item name={name} rules={rules} className={className} >
            <InputNumber
                onKeyPress={onKeyPress}
                autoComplete={autoComplete}
                placeholder={placeholder}
                disabled={disabled}
                addonAfter={addonAfter}
                min={min}
                max={max}
                step={step}
                precision={precision}
                
            />
        </Form.Item>
    );
};

export default FormInputNumberComponent;
