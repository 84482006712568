import { combineReducers } from "redux";
import { authReducer } from "./authReducer";

// Reducer-
import cellReducer from "./cellReducer";
import facilityReducer from "./facilityReducer";
import { userSelectionReducer } from "./userSelectionReducer";
import facilityListReducer from "./facilityListReducer";
import cellListReducer from "./cellListReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    userSelection: userSelectionReducer,
    facility: facilityReducer,
    cell: cellReducer,
    facilityList: facilityListReducer,
    cellList: cellListReducer,
});

export default rootReducer;
