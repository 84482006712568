import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const name = 'userSelectionInfo';
function createInitialState() {
    const selection = JSON.parse(localStorage.getItem(name));
    return {
        facility: selection?.facility || {},
        cell: selection?.cell || {},
        product: selection?.product || {},
        error: null,
        loading: false,
    };
}

function makeLivelineFacility(payload) {
    if (payload?.facility && !payload.facility.livelineId) {
        const facilityList = JSON.parse(localStorage.getItem('facilityList'));

        if (facilityList.length) {
            const facility = facilityList.find(f => f.id === payload.facility.id);

            if (facility) {
                return {
                    ...payload.facility,
                    livelineId: facility.facilityId
                };
            }
        }
    }

    return payload?.facility || {};
}

function createExtraReducers() {
    return (builder) => {
        setUserSelectionInfo();
        function setUserSelectionInfo() {
            const { pending, fulfilled, rejected } = extraActions.setUserSelectionInfo;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state, action) => {
                    let payload = action.payload;
                    if (payload?.facility) state.facility = makeLivelineFacility(payload);
                    if (payload?.cell) state.cell = payload?.cell;
                    if (payload?.product) state.product = payload?.product;
                    localStorage.setItem(name, JSON.stringify(state));
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error.message;
                    state.loading = false;
                });
        }
    };
}

function createExtraActions() {
    return {
        setUserSelectionInfo: setUserSelectionInfo(),
    };

    function setUserSelectionInfo() {
        return createAsyncThunk(`${name}/setUserSelectionInfo`, async (userSelectionInfo) => {
            return userSelectionInfo;
        });
    }
}


const initialState = createInitialState();
const extraReducers = createExtraReducers();
const extraActions = createExtraActions();

const slice = createSlice({ name, initialState, extraReducers,
    reducers: {
        setUserSelection: function (state, action) {
            const { payload } = action;

            // Update user selection
            const newState = {
                facility: {},
                cell: {},
                product: {},
                ...state,
            };

            if (payload?.facility) newState.facility = makeLivelineFacility(payload);
            if (payload?.cell) newState.cell = payload?.cell;
            if (payload?.product) newState.product = payload?.product;

            localStorage.setItem(name, JSON.stringify(newState));
            return newState;
        },
        logout: (state) => {
            state = createInitialState();
            localStorage.removeItem(name);
        },
    }
});

export const userSelectionActions = { ...slice.actions, ...extraActions };
export const userSelectionReducer = slice.reducer;
